<template>
  <div id="signly-logo">
    <img :src="src" :height="logoHeight" :width="logoHeight" alt="Signly logo">
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  name: 'SignlyLogo',
  mixins: [video],
  props: {
    videoWidth: {
      type: Number,
      default: 0
    },
    image: {
      type: String,
      default: 'signly-new-logo-only-white-original.svg',
      validator: propValue => {
        const validNames = ['signly-new-logo-only-white-original', 'signly-grey', 'signly-teal', 'signly-yellow', 'signly-original', 'sign-icon-white']
        const validExtensions = ['.jpeg', '.jpg', '.png', '.svg']
        const isValidName = validNames.some(name => propValue.startsWith(name))
        const isValidExtension = validExtensions.some(ext => propValue.endsWith(ext))
        return isValidExtension && isValidName
      }
    }
  },
  computed: {
    src () {
      return Defaults.images.baseUrl + this.image
    },
    logoHeight () {
      return Defaults.logo.height[this.videoSize]
    }
  }
}
</script>

<style lang="scss">
#signly-logo {
  img {
    max-width: unset !important;
  }
}
</style>
