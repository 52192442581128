<template>
  <div id="signly-video-controls" class="active">
    <div class="signly-video-button" :style="{ opacity: showControls ? 1 : 0 }">
      <button
          ref="videoButton"
          tabindex="0"
          :title="`${playing ? $t('videoControls.pauseVideoTitle') : $t('videoControls.playVideoTitle')}`"
          :aria-label="`${playing ? $t('videoControls.pauseVideoTitle') : $t('videoControls.playVideoTitle')}`"
          @click="togglePlayPause"
      >
        <fa
            tabindex="0"
            v-if="playing"
            icon="pause"
            :size="iconSize"
        />

        <fa
            v-else
            icon="play"
            :size="iconSize"
        />
      </button>
    </div>
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  name: 'SignlyVideoControls',
  mixins: [video],
  props: {
    playing: {
      type: Boolean,
      default: true
    },
    videoWidth: {
      type: Number,
      default: 0
    },
    showControls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconSize () {
      return Defaults.video.iconSize[this.videoSize]
    }
  },
  methods: {
    togglePlayPause () {
      this.$nextTick(() => {
        this.$refs.videoButton.focus()
      })

      this.$emit('playVideo', !this.playing)
    }
  }
}
</script>
