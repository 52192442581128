<template>
  <div id="signly-alert" ref="alertButton" :class="alertClass" @click="alertAction()" @keyup.space="alertAction()" :tabindex="isInteractive ? '0' : '-1' ">
    <div v-if="isLoading" class="loading" />

    <div id="signly-alert-container">
      <div>
        <p data-signly='{"ignore": true}'>
          {{ message }}
        </p>

        <p v-if="action" id="signly-alert-action" :class="hasActionButton ? 'action-button' : ''" data-signly='{"ignore": true}'>
          <strong>{{ action }}</strong>
        </p>
      </div>

      <div v-if="icon" id="signly-alert-icon">
        <fa :icon="isLoading ? 'sync-alt' : icon" :spin="isLoading" size="3x" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { video } from '@/components/mixins/video'
import Defaults from '@/libs/defaults'

export default {
  name: 'SignlyAlert',
  mixins: [video],
  props: {
    videoWidth: {
      type: Number,
      default: 0
    },
    action: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    requestingTranslation: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Object,
      default: null,
      validator: propValue => {
        const validTypes = Defaults.alerts.map(alert => alert.type)
        return validTypes.some(vt => vt === propValue.type)
      }
    }
  },
  data () {
    return {
      retrying: false
    }
  },
  computed: {
    isInteractive () {
      return this.alert && (this.alert.type === 'notTranslated')
    },
    hasActionButton () {
      return (this.isAlertType('requestSent') || this.isAlertType('serverError'))
    },
    alertClass () {
      return [
        `${this.isInteractive ? 'interactive' : ''}`,
        `${this.isLoading ? 'busy' : ''}`,
        `${this.alert && !this.alert.icon ? 'message-only' : ''}`,
        this.videoSize
      ]
    },
    isLoading () {
      return (this.retrying || this.requestingTranslation)
    }
  },
  methods: {
    onSendRequest () {
      this.$emit('update:requestingTranslation', true)
    },
    onRetry () {
      this.retrying = true
      setTimeout(() => {
        this.$emit('retryConnection')
        this.retrying = false
      }, 1000)
    },
    alertAction () {
      this.$nextTick(() => {
        this.$refs.alertButton.focus()
      })

      if (!this.alert || (this.alert && !this.alert.action) || this.isLoading) {
        return null
      }

      if (this.alert.type === 'notTranslated') {
        return this.onSendRequest()
      } else {
        return this.onRetry()
      }
    },
    isAlertType (alertType) {
      return this.alert && this.alert.type === alertType
    }
  }
}
</script>

<style scoped>

</style>
