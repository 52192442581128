import Defaults from '@/libs/defaults'

export const video = {
  computed: {
    videoSize () {
      if (this.videoWidth >= Defaults.video.LARGE_WIDTH) {
        return 'lg'
      } else if (this.videoWidth < Defaults.video.LARGE_WIDTH && this.videoWidth >= Defaults.video.MEDIUM_WIDTH) {
        return 'md'
      } else if (this.videoWidth < Defaults.video.MEDIUM_WIDTH && this.videoWidth >= Defaults.video.SMALL_WIDTH) {
        return 'sm'
      } else {
        return 'xs'
      }
    }
  }
}
