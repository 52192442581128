export default class Swipe {
  constructor (videoUrl, el, callBack, elIndex) {
    this.videoUrl = videoUrl
    this.el = el
    this.callBack = callBack
    this.elIndex = elIndex
    this.touchstartX = 0
    this.touchendX = 0
    this.touchstartY = 0
    this.touchendY = 0
    this.mousedownX = 0
    this.mouseupX = 0
    this.mousedownY = 0
    this.mouseupY = 0
    this.thresholdX = 80

    // Bind event handlers
    this._mouseDownHandler = this._mouseDownHandler.bind(this)
    this._mouseUpHandler = this._mouseUpHandler.bind(this)
    this._touchStartHandler = this._touchStartHandler.bind(this)
    this._touchEndHandler = this._touchEndHandler.bind(this)

    // Add event listeners
    this.el.addEventListener('mousedown', this._mouseDownHandler, { passive: true })
    this.el.addEventListener('mouseup', this._mouseUpHandler, { passive: true })
    this.el.addEventListener('touchstart', this._touchStartHandler, { passive: true })
    this.el.addEventListener('touchend', this._touchEndHandler, { passive: true })
  }

  _mouseDownHandler (e) {
    this.mousedownX = e.offsetX
  }

  _mouseUpHandler (e) {
    this.mouseupX = e.offsetX
    this._handleMouse()
  }

  _touchStartHandler (e) {
    this.touchstartX = e.changedTouches[0].screenX
    this.touchstartY = e.changedTouches[0].screenY
  }

  _touchEndHandler (e) {
    this.touchendX = e.changedTouches[0].screenX
    this.touchendY = e.changedTouches[0].screenY
    this._handleTouch()
  }

  _handleTouch () {
    const distanceX = Math.abs(this.touchstartX - this.touchendX)

    if (distanceX < this.thresholdX) {
      return
    }

    this._playVideo()
  }

  _handleMouse () {
    const distanceX = Math.abs(this.mousedownX - this.mouseupX)

    if (distanceX < this.thresholdX) {
      return
    }

    this._playVideo()
  }

  _playVideo () {
    this.callBack({
      videoUrl: this.videoUrl,
      element: this.el,
      elementIndex: this.elIndex
    })
  }

  removeEventListeners () {
    this.el.removeEventListener('mousedown', this._mouseDownHandler)
    this.el.removeEventListener('mouseup', this._mouseUpHandler)
    this.el.removeEventListener('touchstart', this._touchStartHandler)
    this.el.removeEventListener('touchend', this._touchEndHandler)
  }
}
